<template>
  <div>
    <a-card v-if="myRole && myRole.indexOf('2') > -1">
      <div>申请审批内容</div>
      <QuillEditor v-model="form.applyContent" class="margin-t20"></QuillEditor>
      <div class="display-f margin-t20 justify-end align-items-c">
        <span>注：重复提交会覆盖当前流程</span>
        <a-button type="primary" class="margin-l10" @click="addApprove">申请审批</a-button>
      </div>
    </a-card>
    <a-row :gutter="10" class="margin-t15">
      <a-col span="14">
        <a-card title="审批日志" :bodyStyle="{'height' : '400px', 'overflow-y' : 'auto'}">
          <div class="padding-15">
            <template v-if="complete">
              <template v-if="(logList && logList.length)">
                <div class="display-f align-items-c margin-b20" v-for="(item, index) in logList" :key="item.id">
                  <a-icon type="check" v-if="item.approveStatus === 1"/>
                  <a-icon type="close" v-else/>
                  <img src="@/assets/image/common/header.png" alt="" class="margin-l20 width-40">
                  <div class="margin-l20">
                    <div>
                      <span class="font-wb">{{ item.approver }}</span>
                      <span class="font-c9 margin-l10">{{ item.createTime }}</span>
                    </div>
                    <div class="margin-t10">{{ item.comments }}</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <a-empty style="margin-top: 40px"></a-empty>
              </template>
            </template>
          </div>
        </a-card>
      </a-col>
      <a-col span="10">
        <a-card title="审批流程控制" :bodyStyle="{'height': '400px'}">
          <div slot="extra">
            <a-button size="small" type="primary" ghost @click="flowSubmit" v-if="myRole && myRole.indexOf('1') > -1">保存</a-button>
          </div>
          <div class="padding-40" style="height: calc(100% - 60px)">
            <template  v-for="(item, index) in flowList">
              <div class="display-f justify-between align-items-c margin-b15" :key="item.id">
                <span class="width-20 height-20 border text-c line-h20 border-r50 font-s12">{{ index + 1}}</span>
                <span class="margin-l15">{{ item.approver }}</span>
                <span class="margin-l20 flex1">{{ item.postName || '' }}</span>
                <img src="@/assets/image/common/up.png" alt="" class="cursor width-15" :class="{'visibility-h' : index === 0}" @click="moveUp(index)" v-if="myRole && myRole.indexOf('1') > -1">
                <img src="@/assets/image/common/down.png" alt="" class="cursor width-15 margin-l15" :class="{'visibility-h' : index >= flowList.length - 1}" @click="moveDown(index)" v-if="myRole && myRole.indexOf('1') > -1">
                <a-popconfirm title="确定删除?" @confirm="() => delFlow(item, index)">
                  <img src="@/assets/image/common/del.png" alt="" class="cursor margin-l15"  v-if="myRole && myRole.indexOf('1') > -1">
                </a-popconfirm>
              </div>
            </template>
          </div>
          <a-row class="margin-t20" v-if="myRole && myRole.indexOf('1') > -1">
            <a-form-model
                ref="form"
                labelAlign="right"
                layout="inline"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 17 }"
                :model="flowForm"
                :rules="rules"
                :hideRequiredMark="true"
            >
              <a-row>
                <a-col span="10">
                  <a-form-model-item style="width: 100%" label="触发条件" prop="expenditureType">
                    <a-select v-model="flowForm.expenditureType" placeholder="请选择触发条件" :disabled="!!(flowList.length && flowForm.expenditureType)">
                      <a-select-option :value="0">无</a-select-option>
                      <a-select-option :value="1">任务申请进行</a-select-option>
                      <a-select-option :value="2">任务申请完成</a-select-option>
                      <a-select-option :value="3">任务申请取消</a-select-option>
                      <a-select-option :value="4">任务申请搁置</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="10">
                  <a-form-model-item style="width: 100%" label="审批人" prop="approverId">
                    <a-select v-model="flowForm.approverId" placeholder="请选择审批人" @change="approverChange">
                      <a-select-option :value="item.id" v-for="(item, index) in memberList" :key="item.id">{{ item.staffName }}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col span="4">
                  <a-form-model-item :wrapper-col="{ span: 24}">
                    <a-button type="primary" block @click="addFlow">确认添加</a-button>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import QuillEditor from '@/components/Editor/QuillEditor'
import {
  addTaskApproval,
  addTaskFlows,
  delTaskFlows,
  getTaskApproval,
  getTaskDetail, getTaskFlowsList, updateFlows, updateTaskFlows
} from '../../../api/task/taskDetail'
import { getStaffList } from '../../../api/humanResources/staff'
export default {
  name: 'approval',
  components: {
    QuillEditor
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      form: {
        taskId: parseInt(this.id)
      },
      flowForm: {},
      logList: [],
      flowList: [],
      canModify: false,
      myRole: null,
      rules: {
        expenditureType: [
          { required: true, message: '请选择触发条件', trigger: 'change' }
        ],
        approverId: [
          { required: true, message: '请选择审批人', trigger: 'change' }
        ],
      },
      memberList: [],
      complete: false,
      expenditureType: ''
    }
  },
  mounted () {
    this.getDetail()
    this.getApprovalList()
    this.getApprover()
    this.getFlowList()
  },
  methods: {
    getDetail () {
      getTaskDetail({
        id: this.id
      }).then(res => {
        this.myRole = res.data.myTaskRole
      })
    },
    getApprovalList () {
      getTaskApproval({
        taskId: this.id,
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.logList = res.data.list
        this.complete = true
      })
    },
    addApprove () {
      addTaskApproval(this.form).then(() => {
        this.getApprovalList()
        this.$message.success('申请成功')
      })
    },
    getApprover () {
      getStaffList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.memberList = res.data.list
      })
    },
    addFlow () {
      this.$refs.form.validate( valid => {
        if (valid) {
          this.flowList.push({ ...this.flowForm})
          this.expenditureType = this.flowForm.expenditureType
          this.flowForm = {}
        }
      })
    },
    delFlow (item, index) {
      this.flowList.splice(index, 1)
    },
    flowSubmit () {
      updateFlows({
        flows: this.flowList,
        taskId: this.id,
        triggerCondition: this.expenditureType
      }).then(() => {
        this.getApprovalList()
        this.getFlowList()
        this.$message.success('添加成功！')
      })
    },
    getFlowList () {
      getTaskFlowsList({
        taskId: this.id
      }).then(res => {
        this.flowList = res.data.flows || []
      })
    },
    moveUp(index) {
      let arr = this.flowList;
      arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]));
      this.flowList.forEach((item, index) => {
        item.flowOrder = index
      })
      this.flowList.sort((a, b) => {
        return a.flowOrder - b.flowOrder
      })
      this.$forceUpdate()
    },
    // 下移
    moveDown(index) {
      let arr = this.flowList;
      arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]));
      this.flowList.forEach((item, index) => {
        item.flowOrder = index
      })
      this.flowList.sort((a, b) => {
        return a.flowOrder - b.flowOrder
      })
      this.$forceUpdate()
    },
    approverChange (value, option) {
      this.flowForm.approver = this.memberList.find(item => item.id === value).staffName
    }
  }
}
</script>

<style scoped>

</style>
